.loading {
  background-color: #d5d0ca;
  color: #343333;
  width: 100vw;
  height: 100vh;
}

.loading p {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
